.hd-popover {
    --hd-popover-shadow: 0 0.25rem 0.625rem 0.25rem rgb(60 60 60 / 8%);

    background-color: var(--hd-color-neutral-surface);
    border: var(--hd-border-size) solid var(--hd-color-neutral-border);
    border-radius: var(--hd-border-radius-md);
    box-shadow: var(--hd-popover-shadow);
    overflow-y: auto;
    scroll-margin-top: var(--hd-space-8);
    scrollbar-width: thin;
    scrollbar-color: var(--hd-scrollbar-color) transparent;
}
